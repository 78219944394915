import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
// import store from "@/store";
// import router from "@/router";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.gemImage = data.gemImage;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  preload() {
    // if (store.state.miniGame.data.isEnergySpend) {
      // store.commit("updateMiniGame", false);
      // Loading logic BEGIN
      var progressBar = this.add.graphics();
      var progressBox = this.add.graphics();
      progressBox.fillStyle(0x222222, 0.8);
      progressBox.fillRect(100, 270, 280, 50);

      var width = this.cameras.main.width;
      var height = this.cameras.main.height;

      var loadingText = this.make.text({
        x: width / 2,
        y: height / 2 - 50,
        text: i18n.t("miniGames.general.loading") + "...",
        style: {
          font: "20px monospace",
          fill: "#000000",
        },
      });
      loadingText.setOrigin(0.5, 0.5);

      var percentText = this.make.text({
        x: width / 2,
        y: height / 2 - 5,
        text: "0%",
        style: {
          font: "18px monospace",
          fill: "#000000",
        },
      });
      percentText.setOrigin(0.5, 0.5);

      var assetText = this.make.text({
        x: width / 2,
        y: height / 2 + 50,
        text: "",
        style: {
          font: "18px monospace",
          fill: "#000000",
        },
      });
      assetText.setOrigin(0.5, 0.5);

      this.load.on("progress", function (value) {
        percentText.setText(parseInt(value * 100) + "%");
        progressBar.clear();
        progressBar.fillStyle(0x000000, 1);
        progressBar.fillRect(110, 280, 260 * value, 30);
      });

      this.load.on("fileprogress", function (file) {
        assetText.setText("Loading asset: " + file.key);
      });
      this.load.on("complete", function () {
        progressBar.destroy();
        progressBox.destroy();
        loadingText.destroy();
        percentText.destroy();
        assetText.destroy();
      });
      // Loading logic END
      this.load.image("bg", "/assets/miningGame/bg.png");
      this.load.image("logo", "/assets/miningGame/mining_logo.png");
      this.load.image("wall", "/assets/miningGame/wall.png");
      this.load.image("play", "/assets/miningGame/play.png");
      this.load.image("bomb0", "/assets/miningGame/bomb0.png");
      this.load.image("bomb1", "/assets/miningGame/bomb1.png");
      this.load.image("gem", "/assets/miningGame/gem.png");
      this.load.image("steelWall", "/assets/miningGame/steelWall.png");
      this.load.image("btnClose", "/assets/BtnClose.png");

      this.load.image("clock", "/assets/clock.png");
      this.load.image("round", "/assets/round.png");
      this.load.image("settingPanel", "/assets/settingPanel.png");
      this.load.image("optionButton", "/assets/optionButton.png");
      this.load.image("setting", "/assets/BtnSetting.png");
      this.load.image("score", "/assets/ScoreBoard.png");
      this.load.image("answerback", "/assets/answerback.png");
      this.load.image("answer", "/assets/answer.png");
      this.load.image("correct", "/assets/correct.png");
      this.load.image("wrong", "/assets/wrong.png");
      this.load.image("hamochiCorrect", "/assets/answerCorrect.png");
      this.load.image("hamochiWrong", "/assets/answerWrong.png");
      this.load.image("excl", "/assets/excl.png");
      this.load.image("speech", "/assets/speech.png");
      this.load.image("btn", "/assets/playbtn.png");
      this.load.image("freezeClock", "/assets/miningGame/freeze_clock.png");
      this.load.image(
        "freezeTimeBar",
        "/assets/miningGame/freeze_time_bar.png"
      );

      // testing
      this.load.image("bubbleClock", "/assets/miningGame/BubbleClock.png");

      this.load.image("thief", "/assets/miningGame/boss/thief.png");
      this.load.image("evil", "/assets/miningGame/boss/evil.png");
      this.load.image("blink", "/assets/miningGame/boss/blink.png");
      this.load.image("bossGem", this.gemImage);

      // load aprite sheets
      this.load.spritesheet("pickaxe", "/assets/miningGame/pickaxe.png", {
        frameWidth: 100,
        frameHeight: 100,
      });
      this.load.spritesheet(
        "pickaxeBoss",
        "/assets/miningGame/boss/pickaxe_boss.png",
        {
          frameWidth: 100,
          frameHeight: 100,
        }
      );
      this.load.spritesheet(
        "steel_tile",
        "/assets/miningGame/steel_sprite.png",
        {
          frameWidth: 100,
          frameHeight: 100,
        }
      );
      this.load.spritesheet(
        "stone_tile",
        "/assets/miningGame/stone_sprite.png",
        {
          frameWidth: 100,
          frameHeight: 100,
        }
      );
      this.load.spritesheet("dirt_tile", "/assets/miningGame/dirt_sprite.png", {
        frameWidth: 100,
        frameHeight: 100,
      });
      this.load.spritesheet("boom", "/assets/miningGame/boom.png", {
        frameWidth: 100,
        frameHeight: 100,
      });
      this.load.spritesheet("stones", "/assets/miningGame/stonesanim.png", {
        frameWidth: 400,
        frameHeight: 400,
      });

      this.load.spritesheet("breaking", "/assets/miningGame/breaking.png", {
        frameWidth: 400,
        frameHeight: 400,
      });

      this.load.spritesheet(
        "explosion",
        "/assets/miningGame/explosion_sprite.png",
        {
          frameWidth: 500,
          frameHeight: 500,
        }
      );

      this.load.spritesheet(
        "smoke_bomb_sprite",
        "/assets/miningGame/boss/smoke_bomb_sprite.png",
        {
          frameWidth: 100,
          frameHeight: 100,
        }
      );

      this.load.spritesheet(
        "freeze_bomb_sprite",
        "/assets/miningGame/freeze_bomb_sprite.png",
        {
          frameWidth: 100,
          frameHeight: 100,
        }
      );

      this.load.spritesheet(
        "smoke_sprite",
        "/assets/miningGame/boss/smoke_sprite.png",
        {
          frameWidth: 500,
          frameHeight: 500,
        }
      );

      // load audio files
      this.load.audio("digStone", "/assets/miningGame/sounds/tile_click.mp3");
      this.load.audio("digGem", "/assets/miningGame/sounds/gem_click.mp3");
      this.load.audio(
        "spillStones",
        "/assets/miningGame/sounds/stones_falling.mp3"
      );

      // load audio files
      this.load.audio("gemSplash", "/assets/miningGame/sounds/gem_splash.mp3");
      this.load.audio("bombing", "/assets/miningGame/sounds/bomb.wav");
      this.load.audio("bombing_2", "/assets/miningGame/sounds/bomb_2.wav");
      this.load.audio("evil", "/assets/miningGame/sounds/evil.wav");
      this.load.audio("bombThrow", "/assets/miningGame/sounds/bomb_throw.wav");
      this.load.audio(
        "warningAlarm",
        "/assets/miningGame/sounds/warning_alarm.wav"
      );
      this.load.audio(
        "smokeBombing",
        "/assets/miningGame/sounds/smoke_bomb.wav"
      );
      this.load.audio(
        "correctAnswer",
        "/assets/miningGame/sounds/correct_answer.wav"
      );
      this.load.audio(
        "wrongAnswer",
        "/assets/miningGame/sounds/wrong_answer.wav"
      );
      this.load.audio(
        "scoreIncrease",
        "/assets/miningGame/sounds/score_increase.wav"
      );
      this.load.audio(
        "collectGem",
        "/assets/miningGame/sounds/collect_gem.wav"
      );
      this.load.audio("bombClick", "/assets/miningGame/sounds/bomb_click.wav");
      this.load.audio("startGame", "/assets/miningGame/sounds/start_game.wav");
      this.load.audio(
        "simpleClick",
        "/assets/miningGame/sounds/simple_click.wav"
      );
      this.load.audio("failure", "/assets/miningGame/sounds/failure.wav");
      this.load.audio("success", "/assets/miningGame/sounds/success.wav");
      this.load.audio("close", "/assets/miningGame/sounds/close.wav");
      this.load.audio("smoke", "/assets/miningGame/sounds/smoke.wav");
      this.load.audio("stealGem", "/assets/miningGame/sounds/steal_gem.wav");
      this.load.audio("round", "/assets/miningGame/sounds/round.wav");
      this.load.audio("enter", "/assets/miningGame/sounds/enter.wav");
      this.load.audio("timerGame", "/assets/miningGame/sounds/timer_game.wav");
      this.load.audio("timerBomb", "/assets/miningGame/sounds/timer_bomb.wav");
      this.load.audio("ready", "/assets/miningGame/sounds/ready_go.wav");
      this.load.audio("go", "/assets/miningGame/sounds/go.wav");
      this.load.audio("bossDrum", "/assets/miningGame/sounds/boss_drum.wav");
      this.load.audio(
        "bossWhistle",
        "/assets/miningGame/sounds/boss_whistle.wav"
      );
      this.load.audio("emoji", "/assets/miningGame/sounds/emoji.wav");
      this.load.audio("gemBlink", "/assets/miningGame/sounds/blink.wav");
      this.load.audio("gemLost", "/assets/miningGame/sounds/gem_lost.wav");
      this.load.audio("cleared", "/assets/miningGame/sounds/cleared.wav");
      this.load.audio("bg", "/assets/miningGame/sounds/bg.mp3");
      this.load.audio("freeze", "/assets/miningGame/sounds/freeze.wav");

      // load avatar data
      for (var key of Object.keys(this.avatar)) {
        if (this.avatar[key] && key != "color") {
          this.load.image(key, this.avatar[key]);
        }
      }

      // load friend avatar data
      for (var _key of Object.keys(this.friend.avatar)) {
        if (this.friend.avatar[_key] && _key != "color") {
          this.load.image(_key, this.friend.avatar[_key]);
        }
      }

      // load avatar masks
      this.load.image("angry", "/assets/Mask/M0021.png");
      this.load.image("nice", "/assets/Mask/M0012.png");
      this.load.image("fear", "/assets/Mask/M0040.png");
      this.load.image("sad", "/assets/Mask/M0031.png");
      this.load.image("happy", "/assets/Mask/M0013.png");
      this.load.image("mad", "/assets/Mask/M0080.png");
      this.load.image("stun", "/assets/Mask/M0060.png");
    // } else {
    //   router.push({
    //     name: "PageMiningEnter",
    //   });
    // }
  }

  create() {
    this.scene.start("LandingScene", {
      avatar: this.avatar,
      questions: this.questions,
      friend: this.friend,
      bossRound: this.bossRound,
      env: this.env,
      noOfRounds: this.noOfRounds,
      difficulty: this.difficulty,
    });
  }
}
